.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-band-card-square {
  width:90vw;
  height:90vw;
  margin:5vw;
  max-width: 500px;
  max-height: 500px;
  
  position: relative;
}

.main-band-card-inner {
  position: relative;
  border: #282c34 solid 1px;
  box-shadow: 10px 10px #212529;
  margin: auto;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  color: white;
  background-color:#282c34;
}

.main-band-card-img {
  filter: grayscale(100%) blur(3px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.5s ease-in-out;
}

.main-band-card-square:hover .main-band-card-img {
  filter: grayscale(0%) blur(0px);
}

/*
.main-band-card-square {
  backdrop-filter: grayscale(100%);
  transition: 0.5s ease;
  
}


.main-band-card-square:hover {
  filter: grayscale(0%);
}*/

.woodside-nav-logo {
  transition: 0.3s ease;
  will-change: transform;
}

